import React, {useState, useEffect} from 'react';
import {navigate} from "gatsby";
import './Register.scss';

import Select from '@components/Select';
import {getRestCountries, register} from '@repository/UserRepository';
import {getUniversities} from '@repository/UniversityRepository';

function Register() {
  const [universities, setUniversities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [inputs, setInputs] = useState({
    fullname: '',
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
    gender: '',
    country_code: '',
    university_id: '',
    profile_picture: '',
    university_name: null,
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadUniversities, setIsLoadUniversities] = useState(false);
  const [isLoadCounties, setIsLoadCountries] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [searchName, setSearchName] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowPasswordConfirmation, setIsShowPasswordConfirmation] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    handleGetCounties();
  }, []);

  useEffect(() => {
    if (inputs.university_id !== "others") {
      setInputs((currentValue) => (
        {
          ...currentValue,
          university_name: null,
        }
      ));
    }
  }, [inputs.university_id]);
  
  useEffect(() => {
    handleGetUniversities();
  }, [searchName]);

  const handleGetCounties = () => {
    setIsLoadCountries(true);
    getRestCountries()
      .then((res) => {
        setIsLoadCountries(false);
        let _countries = res.data.map((country) => ({
          label: country.name,
          value: country.alpha3Code
        }));
        setCountries(_countries);
      })
      .catch((err) => {
        setIsLoadCountries(false);
        console.log(err.response);
      })
  }

  const handleGetUniversities = () => {
    if (searchName.length >= 3) {
      setIsLoadUniversities(true);
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(function () {
        if (searchName.length < 3) {
          setUniversities([]);
          setIsLoadUniversities(false);
        } else {
          getUniversities(searchName)
            .then((res) => {
              let _universities = res.data.data.map((university) => ({
                label: university.name,
                value: university._id
              }));
              setUniversities(_universities);
            })
            .catch((err) => {
              console.log(err.response);
              setUniversities([]);
            })
            .finally(() => {
              setIsLoadUniversities(false);
            });
        }
      }, 1500),
    );
  }

  const handleOnInputsChanged = (e) => {
    let label = e.target.name;
    let value = e.target.value;
    setInputs((currentValue) => (
      {
        ...currentValue,
        [label]: value,
      }
    ));
  }

  const handleOnRegister = () => {
    setIsLoading(true);
    setErrors({});
    register(inputs)
      .then((res) => {
        setIsLoading(false);
        navigate("/login?is_register_success=1");
      })
      .catch((err) => {
        if (err.response.data.status_code === 422) {
          setErrors(err.response.data.errors);
        }
        setIsLoading(false);
        console.log(err.response);
      })
  }

  const hasValidInputs = () => {
    if (
      !inputs.fullname ||
      !inputs.username ||
      !inputs.email ||
      !inputs.password ||
      !inputs.password_confirmation ||
      !inputs.gender ||
      !inputs.country_code ||
      !inputs.university_id
    ) {
      return false;
    }

    if (inputs.university_id === 'others' && !inputs.university_name) {
      return false;
    }
    return true;
  }
  return (
    <div id="register" className="container py-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 d-flex flex-column">
          <div className="dosis-title-1 mx-auto">
            Register to Cerebro
          </div>
          <div className="divider bg-abalone-gray align-self-center my-4" />
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className={`form-group d-flex flex-column-reverse ${errors.email && 'mb-1'}`}>
                <input
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="e.g. johnsmith@mail.com"
                  type="email"
                  onChange={handleOnInputsChanged}
                  value={inputs.email}
                  onKeyPress={event => {
                    if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                      handleOnRegister();
                    }
                  }}
                  required
                />
                <label htmlFor='email' className='input-label'>
                  E-mail address
                </label>
              </div>
              {errors.email && (
                <div className="asap-caption-1 text-apple-red">{errors.email[0]}</div>
              )}
            </div>
            <div className="col-lg-6 mb-3">
              <div className={`form-group d-flex flex-column-reverse ${errors.fullname && 'mb-1'}`}>
                <input
                  id="fullname"
                  name="fullname"
                  className="form-control"
                  placeholder="e.g. John Smith"
                  onChange={handleOnInputsChanged}
                  value={inputs.fullname}
                  onKeyPress={event => {
                    if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                      handleOnRegister();
                    }
                  }}
                  required
                />
                <label htmlFor='fullname' className='input-label'>
                  Full Name
                </label>
              </div>
              {errors.fullname && (
                <div className="asap-caption-1 text-apple-red">{errors.fullname[0]}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className={`form-group d-flex flex-column-reverse position-relative ${errors.password && 'mb-1'}`}>
                <input
                  id="password"
                  type={isShowPassword ? "text" : "password"}
                  name="password"
                  className="form-control input-password"
                  placeholder="Enter your password"
                  onChange={handleOnInputsChanged}
                  value={inputs.password}
                  onKeyPress={event => {
                    if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                      handleOnRegister();
                    }
                  }}
                  required
                />
                <label htmlFor='password' className='input-label'>
                  Password
                </label>
                <div
                  className={`text-steel-gray mdi ${isShowPassword ? 'mdi-eye' : 'mdi-eye-off'} mdi-24px icon-eye`}
                  onClick={() => {
                    setIsShowPassword((prevValue) => !prevValue);
                  }}
                />
              </div>
              {errors.password && (
                <div className="asap-caption-1 text-apple-red">{errors.password[0]}</div>
              )}
            </div>
            <div className="col-lg-6 mb-3">
              <div className={`form-group d-flex flex-column-reverse position-relative ${errors.password_confirmation && 'mb-1'}`}>
                <input
                  id="repassword"
                  type={isShowPasswordConfirmation ? "text" : "password"}
                  name="password_confirmation"
                  className="form-control input-password"
                  placeholder="Re-enter your password"
                  onChange={handleOnInputsChanged}
                  value={inputs.password_confirmation}
                  onKeyPress={event => {
                    if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                      handleOnRegister();
                    }
                  }}
                  required
                />
                <label htmlFor='repassword' className='input-label'>
                  Re-enter password
                </label>
                <div
                  className={`text-steel-gray mdi ${isShowPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'} mdi-24px icon-eye`}
                  onClick={() => {
                    setIsShowPasswordConfirmation((prevValue) => !prevValue);
                  }}
                />
              </div>
              {errors.password_confirmation && (
                <div className="asap-caption-1 text-apple-red">{errors.password_confirmation[0]}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className={`form-group d-flex flex-column-reverse position-relative ${errors.country_code && 'mb-1'}`}>
                <Select
                  placeholder="Select Your Country"
                  className="select"
                  options={countries}
                  onChange={(data) => {
                    setInputs((currentValue) => (
                      {
                        ...currentValue,
                        country_code: data.value,
                      }
                    ))
                  }}
                  isLoading={isLoadCounties}
                />
                <label className='input-label'>
                  Country of Origin
                </label>
              </div>
              {errors.country_code && (
                <div className="asap-caption-1 text-apple-red">{errors.country_code[0]}</div>
              )}
            </div>
            <div className="col-lg-6 mb-3">
              <div className={`form-group d-flex flex-column-reverse position-relative ${errors.gender && 'mb-1'}`}>
                <Select
                  placeholder="Select Your Gender"
                  className="select"
                  options={[
                    {
                      label: "Male",
                      value: "male",
                    },
                    {
                      label: "Female",
                      value: "female",
                    },

                  ]}
                  onChange={(data) => {
                    setInputs((currentValue) => (
                      {
                        ...currentValue,
                        gender: data.value,
                      }
                    ))
                  }}
                />
                <label className='input-label'>
                  Gender
                </label>
              </div>
              {errors.gender && (
                <div className="asap-caption-1 text-apple-red">{errors.gender[0]}</div>
              )}
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 mb-3">
              <div className={`form-group d-flex flex-column-reverse ${errors.username && 'mb-1'}`}>
                <input
                  id="username"
                  type="text"
                  name="username"
                  className="form-control"
                  placeholder="Enter your unique username here"
                  onChange={handleOnInputsChanged}
                  value={inputs.username}
                  onKeyPress={event => {
                    if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                      handleOnRegister();
                    }
                  }}
                  required
                />
                <label htmlFor='username' className='input-label'>
                  Username
                </label>
              </div>
              {errors.username && (
                <div className="asap-caption-1 text-apple-red">{errors.username[0]}</div>
              )}
            </div>
            <div className="col-lg-6 mb-3">
              <div className={`form-group d-flex flex-column-reverse position-relative ${errors.university_id && 'mb-1'}`}>
                <Select
                  placeholder="Select Your University"
                  className="select"
                  options={universities}
                  onChange={(data) => {
                    setInputs((currentValue) => (
                      {
                        ...currentValue,
                        university_id: data.value,
                      }
                    ))
                  }}
                  noOptionsMessage={() => searchName.length >= 3 ? 'No options' : 'Enter your university name'}
                  onInputChange={(text) => setSearchName(text)}
                  isLoading={isLoadUniversities}
                  filterOption={(candidate, input) => {
                    return true || candidate.value === "others";
                  }}
                />
                <label className='input-label'>
                  University/College
                </label>
              </div>
              {errors.university_id && (
                <div className="asap-caption-1 text-apple-red">{errors.university_id[0]}</div>
              )}
            </div>
            {inputs.university_id === 'others' && (
              <div className="col-lg-6 mb-3">
                <div className={`form-group d-flex flex-column-reverse position-relative ${errors.university_name && 'mb-1'}`}>
                  <input
                    id="university_name"
                    type="text"
                    name="university_name"
                    className="form-control"
                    placeholder="Enter your university here"
                    onChange={handleOnInputsChanged}
                    value={inputs.university_name}
                    onKeyPress={event => {
                      if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                        handleOnRegister();
                      }
                    }}
                    required
                  />
                  <label className='input-label'>
                    Other University
                  </label>
                </div>
                {errors.university_name && (
                  <div className="asap-caption-1 text-apple-red">{errors.university_name[0]}</div>
                )}
              </div>
            )}
          </div>
          <button
            className="button-md button-primary"
            onClick={handleOnRegister}
            disabled={isLoading || !hasValidInputs()}>
            {isLoading ? <div className="mdi mdi-loading mdi-spin" /> : "Register Now"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Register;
